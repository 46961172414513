import { useGetAllUsers } from "../user/users";
import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { IExtendedUserInfo } from "../../interfaces/user";
import { activateSMTPConfiguration, sendSmtpEmail } from "../../api/email-service";
import { useHistory } from "react-router";

const pagination = {
    page: 0,
    pageSize: 10,
}

export const useEmailServiceControl = (sendGridTemplate: any, setFile?: any) => {
    const history: any = useHistory()
    const userInfoFromHistoryState = history?.location?.state
    const [receivers, setReceivers] = useState<string[]>(["All", "HU", "FU"])
    const [bccReceivers, setBccReceivers] = useState<string[]>([])
    const [groupReceivers, setGroupReceivers] = useState<string[]>([])
    const [searchValue, setSearchValue] = useState<string>("")
    const [searchBccValue, setSearchBccValue] = useState<string>("")
    const [selectedUserName, setSelectedUserName] = useState<string>("")
    const [selectedBccUserName, setSelectedBccUserName] = useState<string>('')
    const [emailBody, setEmailBody] = useState<string>("")
    const [emailFrom, setEmailFrom] = useState<any>(userInfoFromHistoryState ? userInfoFromHistoryState?.from : '')
    const [emailSubject, setEmaiSubject] = useState<string>("")
    const [emailCC, setEmailCC] = useState<string>("")
    const [emailBCC, setEmailBCC] = useState<string>("")
    const [emailType, setEmailType] = React.useState<string>("");
    const [disableSend, setDisableSend] = useState<boolean>(true)
    const [sendEmailLoading, setSendEmailLoading] = useState<boolean>(false)
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false)
    const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const { usersData, loading } = useGetAllUsers({ pagination, search_by: searchValue, sort_by: "COUNTRY_SYSTEM" })
    const bccUsersData = useGetAllUsers({ pagination, search_by: searchBccValue, sort_by: "COUNTRY_SYSTEM" })
    const [csvData, setCsvData] = useState<any[]>();
    const [isUploadCsv, setIsUploadCsv] = useState<boolean>(false);
    const [addedEmailsFromFileCount, setAddedEmailsFromFileCount] = useState<number>(0)

    const autoCompleteOptions = useMemo(() => {
        if (usersData) {
            return usersData?.items?.map((i: IExtendedUserInfo) => i.first_name)
        } else {
            return []
        }
    }, [usersData])

    const autoCompleteBccOptions = useMemo(() => {
        if (bccUsersData?.usersData) {
            return bccUsersData?.usersData?.items?.map((i: IExtendedUserInfo) => i.first_name)
        } else {
            return []
        }
    }, [bccUsersData?.usersData])

    useEffect(() => {
        const user = usersData?.items?.find((user: IExtendedUserInfo) => user.first_name === selectedUserName)
        if (user) {
            const removeDefaultAllValue = receivers.filter((r) => r !== "All" && r !== "HU" && r !== "FU")
            if (!receivers.includes(user?.email)) {
                setReceivers([...removeDefaultAllValue, user?.email])
                setGroupReceivers([])
                setSelectedUserName("")
            }
        } else if (csvData) {
            const dynamicData = csvData && csvData?.map((row: any) => {
                const obj = { "email": row[0], "name": row[1] }; // Create an object with email and name keys
                return obj; // Return the object for each row
            });
            setAddedEmailsFromFileCount(dynamicData.length)
        }
        // eslint-disable-next-line
    }, [selectedUserName, csvData])

    useEffect(() => {
        const user = bccUsersData?.usersData?.items?.find((user: IExtendedUserInfo) => user.first_name === selectedBccUserName)
        if (user) {
            if (!bccReceivers.includes(user.email)) {
                setBccReceivers([...bccReceivers, user.email])
            }
        }
        // eslint-disable-next-line
    }, [selectedBccUserName])

    useEffect(() => {
        if (receivers.length === 0) {
            setReceivers(["All", "HU", "FU"]);
            setIsUploadCsv(false);
            setCsvData([]);
        }
    }, [receivers])

    useEffect(() => {
        if (userInfoFromHistoryState) {
            setSelectedUserName(userInfoFromHistoryState.first_name)
            setReceivers([userInfoFromHistoryState.email])
        }
    }, [userInfoFromHistoryState])

    const handleAutoCompleteChange = (event: React.SyntheticEvent<Element, Event>, value: any) => {
        if (groupReceivers.length === 0) {
            setEmailType("");
        }
        setSelectedUserName(value)
    }
    const handleAutoCompleteBccChange = (event: React.SyntheticEvent<Element, Event>, value: any) => {
        setSelectedBccUserName(value[value.length - 1])
    }

    const handleEmailSentFrom = async (event: React.SyntheticEvent<Element, Event>, value: any) => {
        console.log(value, 'handleEmailSentFrom value', event.target)
        setEmailFrom(value);
        await activateSMTPConfiguration(value);
    }

    const handleEmailType = async (event: React.SyntheticEvent<Element, Event>, value: any) => {
        setEmailType(value)
    }

    const handleEmailSubject = (e: string) => {
        setEmaiSubject(e)
    }

    const handleEmailCC = (e: string) => {
        setEmailCC(e)
    }
    const handleEmailBCC = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setEmailBCC(e.target.value)
    }
    
    const handleSearchUsers = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearchValue(e.target.value)
    }
    const handleBccSearchUsers = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearchBccValue(e.target.value)
    }

    function isQuillEmpty(quill: any) {
        // eslint-disable-next-line
        return JSON.stringify(quill.getContents()) == "\{\"ops\":[\{\"insert\":\"\\n\"\}]\}"
    }

    const handleEmailBodyChange = (content: string, delta: any, source: any, editor: any) => {
        setEmailBody(content)
        setDisableSend(isQuillEmpty(editor))
    }

    const handleReceiversDelete = (chip: any) => {
        if (chip === "HU" || chip === "All" || chip === "FU") {
            setGroupReceivers([chip])
            return;
        } else {
            setReceivers((prev) => prev.filter((r) => r !== chip))
        }
    }
    const handleBccReceiversDelete = (chip: any) => {
        setBccReceivers((prev) => prev.filter((r) => r !== chip))
        setSelectedBccUserName('')
    }
    const handleSelectMailGroup = (e: any) => {
        let val = e.target.innerText;
        if (val === 'HU' || val === 'All' || val === 'FU') {
            setGroupReceivers([val])
            if (groupReceivers.includes(val)) {
                setEmailType('');
                // If it's already selected, remove it
                setGroupReceivers(groupReceivers.filter(item => item !== val));
            } else {
                // If it's not selected, add it
                setGroupReceivers([val])
            }
        }
    };

    const onSend = async (appendReceiverName: boolean) => {
        setSendEmailLoading(true);
        const dynamicData = csvData && csvData?.map((row: any) => {
            const obj = { "email": row[0], "name": row[1] }; // Create an object with email and name keys
            return obj; // Return the object for each row
        });
        const fileEmailsArray = dynamicData && dynamicData?.map((obj: any) => obj.email);
        let requestBody = {
            emails: "" as string | string[],
            // eslint-disable-next-line
            html: emailBody.replaceAll("'\'", ""),
            subject: emailSubject,
            from: emailFrom,
            cc: emailCC,
            bcc: bccReceivers,
            template_id: sendGridTemplate?.id,
            appendReceiverName: appendReceiverName,
            emailType: emailType,
            dynamicData: dynamicData && dynamicData?.length > 0 ? dynamicData : ''
        }
        if (groupReceivers.includes("All")) {
            requestBody.emails = "All"
        } else if (groupReceivers.includes("HU")) {
            requestBody.emails = "HU"
        } else if (groupReceivers.includes("FU")) {
            requestBody.emails = "FU"
        } else if (fileEmailsArray && fileEmailsArray?.length > 0) {
            if (emailType === 'TO') requestBody.emails = fileEmailsArray;
            else if (emailType === 'BCC') requestBody.bcc = fileEmailsArray;
        } else if (receivers.includes("FU") && receivers.includes("HU") && receivers.includes("All")) {
            requestBody.emails = ""
        } else {
            requestBody.emails = receivers
        }
        const response = await sendSmtpEmail(requestBody)
        if (response && response?.data?.isExpected) {
            setSendEmailLoading(false)
            setEmailBody("")
            setEmaiSubject("")
            setReceivers(["All", "HU", "FU"])
            setBccReceivers([])
            setSelectedUserName("")
            setSelectedBccUserName("")
            setOpenSuccessModal(true)
            setCsvData([])
            setIsUploadCsv(false)
            setFile("")
            setAddedEmailsFromFileCount(0)
        } else {
            setOpenErrorModal(true)
        }
        return response;
    }

    return {
        receivers,
        bccReceivers,
        setBccReceivers,
        handleSelectMailGroup,
        loading,
        autoCompleteOptions,
        autoCompleteBccOptions,
        handleAutoCompleteChange,
        handleAutoCompleteBccChange,
        searchValue,
        searchBccValue,
        handleSearchUsers,
        handleBccSearchUsers,
        selectedUserName,
        selectedBccUserName,
        emailBody,
        handleEmailBodyChange,
        onSend,
        handleReceiversDelete,
        handleBccReceiversDelete,
        disableSend,
        setDisableSend,
        sendEmailLoading,
        groupReceivers,
        successModal: {
            open: openSuccessModal,
            setOpen: setOpenSuccessModal
        },
        errorModal: {
            open: openErrorModal,
            setOpen: setOpenErrorModal,
            setMessage: setErrorMessage,
            message: errorMessage
        },
        emailFromOptions: [
            process.env.REACT_APP_NO_REPLY,
            process.env.REACT_APP_SERVICE_EMAIL,
            process.env.REACT_APP_FEEDBACK_EMAIL,
            process.env.REACT_APP_SUPPORT_EMAIL,
            process.env.REACT_APP_HIRA_EMAIL,
            process.env.REACT_APP_BURKHARD_EMAIL
        ],
        emailFrom,
        handleEmailSentFrom,
        handleEmailType,
        emailSubject,
        handleEmailSubject,
        handleEmailCC,
        setCsvData,
        setIsUploadCsv,
        isUploadCsv,
        emailCC,
        emailBCC,
        handleEmailBCC,
        emailType,
        addedEmailsFromFileCount,
        csvData,
        setEmailType,
        setEmailFrom
    }
}