import React from "react"
import { useHistory } from "react-router";
import SidebarItems from "../../constant/sidebar-items";
import {Badge} from "@mui/material";
import {useUnreadDataCounter} from "../../service/unread-data-count";

interface IProps {
    item: {
        title: string,
        path: string,
        icon: JSX.Element,
        description: string
    }
}

const OverViewCard: React.FC<IProps> = ({item}) => {
    const history = useHistory()
    const {counter} = useUnreadDataCounter(item.path)
    return (
        <div className="overview__card" onClick={() => history.push(item.path)}>
            <div className="icon">
                <Badge badgeContent={counter} color={"error"}>
                    {item.icon}
                </Badge>
            </div>
            <div className="info">
                <h3>{item.title}</h3>
                <span>{item.description}</span>
            </div>
        </div>
    )
}
const OverView = () => {
    return (
        <div className="overview">
            <div className="overview__content">
                <div className="cards">
                    {SidebarItems.map((item, index) => (
                        <OverViewCard key={index} item={item}/>
                    ))}
                </div>
            </div>

        </div>
    )
}


export default OverView;