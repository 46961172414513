import axios from "axios";
import {ISystemEmailRequest} from "../interfaces/system-email";
import {IGetListOfTemplates, ISendGridVersion} from "../interfaces/sendgrid";

export interface IGetSendGridTemplate {
    templateId: string,
    version: string
}

export const sendSystemEmail = (data: ISystemEmailRequest) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/admin/service/emails`,
        data,
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};


export const getSendGridTemplateById = (data: IGetSendGridTemplate): Promise<ISendGridVersion> => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_SENDGRID_API_URL}/templates/${data.templateId}/versions/${data.version}`,
        headers: {Authorization: `Bearer ${process.env.REACT_APP_SENDGRID_API_KEY}`}
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

export const getListOfSendGridTemplate = (): Promise<IGetListOfTemplates> => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_SENDGRID_API_URL}/templates?generations=legacy,dynamic`,
        headers: {Authorization: `Bearer ${process.env.REACT_APP_SENDGRID_API_KEY}`}
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};

export const activateSendGridTemplate = (template_id: string, version_id: string): Promise<any> => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_SENDGRID_API_URL}/templates/${template_id}/versions/${version_id}/activate`,
        headers: {Authorization: `Bearer ${process.env.REACT_APP_SENDGRID_API_KEY}`}
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};
export const activateTemplate = (template_id: number): Promise<any> => {
    return axios({
        method: "PUT",
        url: `${process.env.REACT_APP_API_URL}/template-version/activate/${template_id}`
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};
export const sendSmtpEmail = (data: ISystemEmailRequest) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/smtp-mail/send`,
        data,
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};
export const activateSMTPConfiguration = (email: string, id?: number): Promise<any> => {
    const data = {
        email: email
    }
    return axios({
        method: "PUT",
        // url: `${process.env.REACT_APP_API_URL}/smtp-mail/activate/${id}`
         url: `${process.env.REACT_APP_API_URL}/smtp-mail/activate`,
         data
    })
        .then((response: any) => response?.data)
        .catch((error) => error.response?.data);
};