import React, { useEffect, useState } from "react"
import Table from "../../components/Table";
import {
    VerificationRequestsHeader,
    VerificationRequestTableName
} from "../../constant/TableHeaders/verification-requests-header";
import { INIT_PAGINATION, PAGE_SIZE } from "../../constant/pagination";
import { usePagination } from "../../service/common/pagination";
import { overflowBody } from "../../utils/style-document";
import { useGetVerificationRequests } from "../../service/verification-requests";
import VerificationRequestInfoCard from "./components/verification-request-info";
import Loader from "../../components/Loader";
import { getVerificationRequests } from "../../api/verification-requests";
import { useUpdateVerificationRequestFromUnreadToRead } from "../../service/verification-requests/update-from-unread-to-read";
import { useHistory } from "react-router-dom";


const VerificationRequests = () => {
    const [verificationRequests, setVerificationRequests] = useState<any>([])
    const [verificationRequestInfo, setVerificationRequestInfo] = useState<any>()
    const [openVerificationRequestCard, setOpenVerificationRequestCard] = useState<boolean>(false)
    const history = useHistory();

    useUpdateVerificationRequestFromUnreadToRead(verificationRequestInfo, setVerificationRequests)

    const [currentPage, setCurrentPage] = useState<number>(0)

    const { verificationRequestsData, loading } = useGetVerificationRequests(
        {
            pagination: INIT_PAGINATION,
            statuses: ["PENDING"]
        }
    )

    const [newData] = usePagination(getVerificationRequests, {
        variables: {
            pagination: {
                page: currentPage + 1,
                pageSize: PAGE_SIZE
            },
            statuses: ["PENDING"]
        }
    });

    useEffect(() => {
        setVerificationRequests(verificationRequestsData?.items)
    }, [verificationRequestsData])

    useEffect(() => {
        if (newData?.items && newData?.items?.length > 0) {
            setVerificationRequests([...verificationRequestsData as any, ...newData?.items])
            setCurrentPage(newData?.currentPage)
        }
        // eslint-disable-next-line
    }, [newData])

    const openVerificationRequest = (value: any) => {
        setOpenVerificationRequestCard(true)
        console.log(value, 'value')
        setVerificationRequestInfo(value)
    }

    overflowBody(openVerificationRequestCard)

    const updateComplaintDataArr = (verificationRequest: any, resolvedVerificationRequest: any) => {
        const onlyActiveRequests = verificationRequests?.filter((i: any) => i?.id !== verificationRequest?.id)
        setVerificationRequests(onlyActiveRequests)
    }

    return (
        <div>
            <div className="back-btn-div">
                <button
                    className="back-btn mt-2"
                    onClick={() => history.goBack()}
                >
                    Back
                </button> </div>
            {loading && <Loader />}
            {openVerificationRequestCard && <VerificationRequestInfoCard
                updateVerificationRequestList={updateComplaintDataArr}
                setOpen={setOpenVerificationRequestCard}
                info={verificationRequestInfo} />}
            {!!verificationRequests?.length ? (
                <div className="verification-requests-table">
                    <Table
                        header={VerificationRequestsHeader}
                        items={verificationRequests}
                        tableName={VerificationRequestTableName}
                        onClickHandler={openVerificationRequest}
                    />
                </div>) :
                <h2 className="nth-found">Nothing found</h2>
            }
        </div>
    )
}

export default VerificationRequests;